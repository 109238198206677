import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaBookReader, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {FaArrowRight, FaArrowLeft} from 'react-icons/fa';
import {BsFillGearFill, BsArrowRight} from 'react-icons/bs';
import { getUser, getAccessToken } from '../api';
import { ImArrowUpRight2 } from 'react-icons/im';

const AppHeader = ({ user, isAuthenticated, handleLogout, setShowAccountPage, 
                     showAccountPage, setUser }) => {
    
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(false);

    const handleAccountLinkClick = async () => {
        setShowSpinner(true);
        const accessToken = await getAccessToken();
        const userData = await getUser(accessToken);
        if (userData) {
            setUser(userData);
        }

        setShowAccountPage(prevState => !prevState);
        setShowSpinner(false);
        console.log('handleAccountLinkClick');
        // navigate('/account', { state: {
        //     user: 'user',
        //     isAuthenticated: 'isAuthenticated'}})
    };
    return (
        <Navbar bg="light" className='custom-navbar'>
            <Link to="/app" style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                <FaBookReader size={32} />
                <span style={{ marginLeft: '10px' }}>Talk To Files</span>
            </Link>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
                {showSpinner && <Spinner animation="border" role="status" />}
                {user ? (
                <div>
                    <Navbar.Text style={{marginRight: '10px'}}>
                    <button 
                        class="text-gray-800 hover:underline hover:text-black"
                        onClick={handleAccountLinkClick}>
                             <strong>Account <ImArrowUpRight2 size={15}/></strong> : {user[0].email}
                    </button>
                    </Navbar.Text>
                </div>
                ) : null}
                {isAuthenticated() ? (
                <Button variant="dark" onClick={handleLogout}>Logout</Button>
                ) : null}
            </Navbar.Collapse>
        </Navbar>
        );
        }      
    
export default AppHeader;