import React, { useState, useRef } from 'react';
import { Form, Button, Container, Spinner, Modal } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import { postDocument, getAccessToken } from '../api.js';
import '../styles.css';

function UploadFile({ updateDocuments }) {
  const [file, setFile] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [documentType, setDocumentType] = useState('');


  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setLoadingFile(true);
      try {
        // handle upload logic here
        const accessToken = await getAccessToken();
        if (!accessToken) {
          console.log('No access token found');
          return;
        }
        const return_info = await postDocument(accessToken, selectedFile, documentType);
        console.log(selectedFile);
        console.log(return_info);
        updateDocuments(); // update the list of documents
        // show the toast
        if (return_info.message && return_info.message.includes("error")){
          setModalTitle('Error');
          setModalMessage(return_info.message);
        } else {
          // don't show the success Modal
          // setModalTitle('Success!');
          // setModalMessage('Your file was uploaded successfully.');
          // setShowModal(true);
        }
      } catch (error) {
        // handle error here
        console.log(error);
        setModalTitle('Error!');
        setModalMessage('An error occurred while uploading your file. Please try again.');
        setModalMessage(error.response.data.error);
        setShowModal(true);
      } finally {
        setLoadingFile(false);
        // clear the file input
        setFile(null);
        // refresh the page
        // window.location.reload();
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.target.value);
  };

  const isDocumentTypeSelected = documentType !== '';

  return (
    <Container>
        <Form>
          <Form.Group>
            <div>
              {/* <Form.Label>Select Document Type</Form.Label> */}
              <Form.Control as="select" value={documentType} onChange={handleDocumentTypeChange}>
                <option value="">-- Select Document Type --</option>
                <option value="general">General</option>
                <option value="financial_loi">Financial: Letter of Intent</option>
                <option value="financial_spa">Financial: Sale and Purchase Agreement</option>
                <option value="financial_termsheet">Financial: Term Sheet</option>
                <option value="financial_other">Financial: Other</option>
              </Form.Control>
            </div>
            {isDocumentTypeSelected ? (
              <div>
                {/* Display text based on the selected document type */}
                {isDocumentTypeSelected && (
                  <div style={{ fontSize: '14px' }}>
                    {documentType === "general" && <p><strong>General</strong>: research papers, gov't docs, technical papers. Fastest upload speed.</p>}
                    {documentType === "financial_loi" && <p><strong>Financial - Letter of Intent</strong>: intent of parties to enter into a business transaction.</p>}
                    {documentType === "financial_spa" && <p><strong>Financial - Sales & Purchase Agreement</strong>: legal contract about the terms of a sale or purchase.</p>}
                    {documentType === "financial_termsheet" && <p><strong>Financial - Term Sheet</strong>: overview of the deal structure and terms.</p>}
                    {documentType === "financial_other" && <p><strong>Financial</strong>: Other financial documents.</p>}
                  </div>
                )}
                <div
                  className="upload-container"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={handleClick}
                >
                  <Form.Control
                    type="file"
                    onChange={handleFileChange}
                    className="file-input"
                    ref={fileInputRef}
                  />
                  {file ? (
                    <div style={{ display: "flex", justifyContent:"center", alignItems: "center" }}>
                      <p className="filename" style={{ textAlign: "center" }}>Selected file: {file.name}</p>
                    </div>
                  ) : (
                    <div style={{ display: "flex", justifyContent:"center", alignItems: "center"  }}>
                      <p className="drag-text" style={{ textAlign: "center" }}>
                        <FaUpload />
                        <br></br>
                        Click to upload
                        <br></br>
                      <div style={{ fontSize: 14}}>
                        Limit: 64 MB
                      </div>
                      <div style={{ fontSize: 14}}>
                        For best results, use a PDF
                      </div>
                      {/* <div style={{ fontSize: 14}}>
                        (also supports .docx and .txt)
                      </div> */}
                      </p>
                      
                    </div>
                  )}

                  {/* Modal */}
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalMessage}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {loadingFile ? (
                    <Spinner animation="border" variant="primary" className="upload-spinner" />
                  ) : null}
                </div>
                {/* <div style={{position: 'relative'}}>
                  {file && !loadingFile ? (
                    <Button variant="primary" className="upload-btn" onClick={handleUpload}>
                      <FaUpload /> Upload
                    </Button>
                  ) : null}
                </div> */}
              </div>
              ) : (
                null
              )}
            </Form.Group>
          </Form>

      </Container>

  );
}

export default UploadFile;