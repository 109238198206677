import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Container, Row, Col, ListGroup, Form, 
         FormGroup, FormControl, Button, Spinner, Modal,
         FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isAuthenticated, logout } from './auth';
import { LoginForm } from './LoginForm';
import { 
  getAccessToken, 
  refreshAccessToken, 
  getQuestions, 
  getQuestionsReturn,
  getDocuments, 
  getUser 
} from './api';
import { FaComment, FaBookReader, FaFilePdf, FaAlignJustify } from 'react-icons/fa';
import jwt_decode from 'jwt-decode';
import './App.css';
import DocumentSelector from './components/DocumentSelector';
import UploadFile from './components/UploadFile';
import DisplaySources from "./components/DisplaySources";
import UploadFileHeader from './components/UploadFileHeader';
import Footer from './components/Footer';
import { URL } from './constants';
import ChatHeader from './components/ChatHeader';
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import QuestionItem from './components/QuestionItem';
import DocumentFaq from './components/DocumentFaq';
import DocumentKeyterms from './components/DocumentKeyterms';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { ToolbarProps } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import AppHeader from './components/AppHeader';
import Account from './components/Account';
import CopyButton from './components/CopyButton';
import DocumentKeyNumbers from './components/DocumentKeyNumbers';
import VidyardPlayer from './components/VidyardPlayer';
import ReactMarkdown from 'react-markdown';
import PulseLoader from "react-spinners/PulseLoader";
import YouTube from 'react-youtube';

import SecurityFAQModal from './components/SecurityFAQModal';
// import DocViewer from "react-doc-viewer";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function App() {
  const languageModels = [
    { label: 'ChatGPT3', value: 'gpt-3.5-turbo' },
    { label: 'GPT3', value: 'text-davinci-003' },
    { label: 'GPT4', value: 'gpt-4' },
  ];

  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [accessToken, setAccessToken] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [selectedDocumentName, setSelectedDocumentName] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showStartModal, setShowStartModal] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedLanguageModel, setSelectedLanguageModel] = useState(languageModels[0].value);
  const [numSources, setNumSources] = useState(5); // number of search results to get from FAISS
  const [showLeftColumn, setShowLeftColumn] = useState(true);
  const [showSecurityFAQModal, setShowSecurityFAQModal] = useState(false);
  const [showAccountPage, setShowAccountPage] = useState(false);
  const [modalQuestionError, setModalQuestionError] = useState('');
  const [showQuestionErrorModal, setShowQuestionErrorModal] = useState(false);
  const [streamingText, setStreamingText] = useState('');
  const [newQuestionSpinner, setNewQuestionSpinner] = useState(false);

  const submitQuestionButtonRef = useRef(null);

  const toggleLeftColumn = () => {
    // setShowLeftColumn(!showLeftColumn);
    setShowLeftColumn(prevState => !prevState);
  };

  // React PDF Viewer
  const searchPluginInstance = searchPlugin();
  const { highlight } = searchPluginInstance;
  const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const handleDocumentLoad = () => setDocumentLoaded(true);
  
  // Trigger the highlight function when the searchKeyword state changes
  React.useEffect(() => {
    if (isDocumentLoaded && searchKeyword) {
        highlight({
            keyword: searchKeyword,
            matchCase: true,
        });
    }
  }, [isDocumentLoaded, searchKeyword]);

  // Update the searchKeyword state based on user input
  const handleKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };
  
  // React.useEffect(() => {
  //   if (isDocumentLoaded) {
  //     console.log('Document loaded');
  //     highlight('medicare');
  //   }
  // }, [isDocumentLoaded]);



  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      // defaultTabs[0], // Bookmarks tab
      // defaultTabs[1], // Thumbnails tab
    ],
  });

  const handleShowSecurityFAQModal = () => {
    setShowSecurityFAQModal(true);
  };


  // const fullScreenPluginInstance = fullScreenPlugin();

  const documentsRef = useRef([]);

  const handleLogin = (accessToken) => {
    setAccessToken(accessToken);
  };

  const handleLogout = () => {
    logout();
    setAccessToken(null);
  };

  // State vars for Formatting:
  const columnClassName = selectedDocumentId ? "column-style mx-auto" : "column-style column-disabled mx-auto";

  const updateAllGets = async () => {
    const accessToken = await getAccessToken();
    setAccessToken(accessToken);
    await getQuestions(accessToken, setQuestions);
    // await getDocuments(accessToken, setDocuments);
    const documentsData = await getDocuments(accessToken);
    setDocuments(documentsData);
    documentsRef.current = documentsData;

    const userData = await getUser(accessToken);
    if (userData) {
      setUser(userData);
    }
    

    if (documentsData.length > 0 && !selectedDocumentId) {
      // turn off auto-selecting the first document
      // setSelectedDocumentId(documentsData[0].document_id);
      // setSelectedDocumentName(documentsData[0].document_name);
    }
    if (documentsData.length === 0) {
      setShowStartModal(true);
    }
  };

  useEffect(() => {
    updateAllGets();
    document.title = 'Talk To Files';
  }, []);

  useEffect(() => {
    // console.log(documentsRef.current);
  }, [documentsRef.current]);

  // handles pressing Tab
  useEffect(() => {
    const handleTabPress = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault(); // prevent tabbing to the next element
        toggleLeftColumn();
        console.log('tab pressed');
        if (selectedDocumentId === null) {
          console.log('documents:');
          console.log(documents);
          setSelectedDocumentId(documents[0].document_id);
        }
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleTabPress);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleTabPress);
    };
  }, []);


  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(URL + 'api/questions/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          question: newQuestion,
          document: selectedDocumentId,
          user: jwt_decode(accessToken).user_id,
          language_model: selectedLanguageModel,
          num_search_results: numSources
        })
      });
      // console.log('response', response);

      const data = await response.json();

      // console.log('data', data);
      // console.log('data.message', data.message);
      // console.log('data.error', data.error);

      if (data.error){
        console.log('Should show error modal')
        setModalQuestionError(data.error);
        setShowQuestionErrorModal(true);
      }
  
      setQuestions([data, ...questions]);
      setNewQuestion('');
      const filtered = questions.filter((question) => question.document === selectedDocumentId);
      setFilteredQuestions([data, ...filtered]);
    } catch (error) {
      console.error(error);
      // setModalQuestionError(error.response.data.error);
      // setShowQuestionErrorModal(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  // function testStream () {
  //   // const accessToken = await getAccessToken();
  //   const eventSource = new EventSource(URL + 'api/question_stream/');
  //   eventSource.onmessage = function (event) {
  //     const content = event.data;
  //     setStreamingText(content);
  //   };
  // }
  function handleSubmitStreaming (e) {
    setIsSubmitting(true);
    setNewQuestionSpinner(true);

    const xhr = new XMLHttpRequest();
      // xhr.open('POST', URL + '/api/question_stream/');
      xhr.open('POST', URL + '/api/questions/');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
      // xhr.send(JSON.stringify({ prompt: 'How do I set up payment invoices? Provide a short, concise response. Return a response with numbered items separated by newlines ("\n\n")' }));

      const requestBody = {
        question: newQuestion,
        document: selectedDocumentId,
        user: jwt_decode(accessToken).user_id,
        language_model: selectedLanguageModel,
        num_search_results: numSources
      };
      xhr.send(JSON.stringify(requestBody));

      // xhr.onreadystatechange = function() {
      //   if (xhr.readyState === XMLHttpRequest.DONE) {
      //     const response = xhr.responseText;
      //     // Update the state variable with the response
      //     setStreamingText(response);
      //   }
      // };
      xhr.onreadystatechange = async function() {
        if (xhr.readyState === XMLHttpRequest.LOADING) {
          setNewQuestionSpinner(false);
          const response = xhr.responseText;
          // Update the state variable with the partial response
          setStreamingText(response);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          const response = xhr.responseText;
          // Update the state variable with the complete response
          setStreamingText(response);
          // sleep 4 seconds
          // console.log('sleeping')
          await new Promise(r => setTimeout(r, 2000));
          // console.log('done sleeping')
          const updatedQuestions = await getQuestionsReturn(accessToken, setQuestions);
          setQuestions(updatedQuestions);
          // console.log('updated questions');
          // console.log(updatedQuestions);
          const filtered = await updatedQuestions.filter((question) => question.document === selectedDocumentId);
          setFilteredQuestions(filtered);

          // const filtered = questions.filter((question) => question.document === selectedDocumentId);
          // setFilteredQuestions([data, ...filtered]);

          setStreamingText(null);
          setNewQuestion('');
          setIsSubmitting(false);

          //setQuestions([])
          // could upload the response (answer) text back to the Server here
          
          // could then call getQuestions() to update the questions list

          // and then update the filtered questions list

          // and then setStreamingText to null so it's ready for the next question
        }
      };
    }

  
  const handleSelectChange = (documentId) => {
    if (selectedDocumentId === documentId) {
      setSelectedDocumentId(null);
      setSelectedDocumentName(null);
      setFilteredQuestions([]);
    } else {
      setSelectedDocumentId(documentId);
      setSelectedDocumentName(documents.find(doc => doc.document_id === documentId).document_name);
      // filter the questions to only show the ones for the selected document
      // fitler in the frontend

      const filtered = questions.filter((question) => question.document === documentId);
      setFilteredQuestions(filtered);

    }
    // Do something with the selected document ID
  };

  const updateDocuments = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      console.log('No access token found');
      return;
    }
    // Get the initial documents data
    let documentsData = await getDocuments(accessToken);
    setDocuments(documentsData);
    documentsRef.current = documentsData;

    // Set the selected document to the first item in the documents list
    console.log('documentsData', documentsData);
    setSelectedDocumentId(documentsData[0].document_id);
    setSelectedDocumentName(documentsData[0].document_name);
    setFilteredQuestions([]);  // clear the filtered questions list

    const maxPollingTime = 5 * 60 * 1000; // 5 minutes in milliseconds
    const checkInterval = 5000; // 5 second in milliseconds
    let elapsedPollingTime = 0;

    // Poll until all documents are complete or maximum polling time is reached
    while (elapsedPollingTime < maxPollingTime) {
      // Check if every document has a status of 'complete'
      const allDocumentsComplete = documentsData.every(document => document.status === 'complete');
      if (allDocumentsComplete) {
        break; // break out of the loop if all documents are complete
      }
      // Wait for the check interval before checking again
      await new Promise(resolve => setTimeout(resolve, checkInterval));
      elapsedPollingTime += checkInterval;
      // Get the updated documents data
      documentsData = await getDocuments(accessToken);
      setDocuments(documentsData);
      documentsRef.current = documentsData;
    }

    // If all documents are complete, set the selected document and update all gets
    if (documentsData.length > 0 && !selectedDocumentId) {
      setSelectedDocumentId(documentsData[0].document_id);
      setSelectedDocumentName(documentsData[0].document_name);
    }
    updateAllGets();
  };

  if (showAccountPage) {
    return (
      <div className="App">
        <AppHeader
          user={user}
          handleLogout={handleLogout}
          isAuthenticated={isAuthenticated}
          setShowAccountPage={setShowAccountPage}
          showAccountPage={showAccountPage}
          setUser={setUser}
        />
        <Account
          user={user}
          handleLogout={handleLogout}
          setShowAccountPage={setShowAccountPage}
          showAccountPage={showAccountPage}
        />
      </div>
    )
  };

  return (
    <div className="App">
      <AppHeader
        user={user}
        handleLogout={handleLogout}
        isAuthenticated={isAuthenticated}
        setShowAccountPage={setShowAccountPage}
        showAccountPage={showAccountPage}
        setUser={setUser}
      />
      {isAuthenticated() ? (
      <Container fluid className="px-0 my-gradient-container mx-auto"
        style={{ height: '100vh' }}
      >
        {/* {showAccountPage ? (
        <Row className="mx-auto">
          <Col className="mx-auto">
            <p>Account</p>
          </Col>
        </Row>
        ) : (null)} */}
        <Row className={columnClassName}>   
        {showLeftColumn ? (
          <Button 
            className='btn btn-dark' 
            style={{ width: '200px', padding: '0 30px' }} 
            onClick={toggleLeftColumn}>
              <FaFilePdf size={40}/> Show PDF 
          </Button>
         ) : (
         <Button 
          className='btn btn-dark' 
          style={{ width: '200px', padding: '0 30px' }} 
          onClick={toggleLeftColumn}>
            <FaAlignJustify size={40}/> Show Menu
          </Button>
         )}
          <ChatHeader 
            selectedDocumentName={ selectedDocumentName }
            document={ documents.find(doc => doc.document_id === selectedDocumentId) }
            setQuestions={setQuestions}
          /> 
          <Modal show={showQuestionErrorModal} onHide={() => setShowQuestionErrorModal(false)}>
          <Modal.Header>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalQuestionError}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowQuestionErrorModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        </Row>
        {/* )} */}
        <Row>
          {/* Show modal if documents is null and showModal is true */}
            {documents.length === 0 && showStartModal && (
              <Modal show={true} onHide={() => setShowStartModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>To being, upload your first document.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowStartModal(false)}>Close</Button>
                </Modal.Footer>
              </Modal>
            )}
          {showLeftColumn && (
          <Col xs={12} md={3}>
          {/* <Button onClick={toggleLeftColumn}> ^ {showLeftColumn ? "Hide" : "Show"} menu</Button> */}
            <>
              {/* <h2>Documents</h2> */}
              <div className="column-style">
                  {documents.length > 0 && (
                <DocumentSelector
                  documents={documents}
                  selectedDocumentId={selectedDocumentId}
                  handleSelectChange={handleSelectChange}
                  />
                  )}
              </div>
              <div className="column-style">
                
                <UploadFileHeader/>

                {/* <div className="m-3">
                  <label className="mx-3">Choose file: </label>
                  <input className="d-none" type="file" />
                  <button className="btn btn-outline-primary">Upload</button>
                </div> */}
                <UploadFile updateDocuments={updateDocuments}/>
              </div>
              <div className="footer-style">
                <button 
                  onClick={handleShowSecurityFAQModal}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                  >Security FAQs?</button>
                <SecurityFAQModal showModal={showSecurityFAQModal} handleCloseModal={() => 
                setShowSecurityFAQModal(false)} />
              </div>
              
            </>
            </Col>
            )}
            {/* PDF VIEWER */}
          {showLeftColumn ? null : (
          <Col xs={12} md={showLeftColumn ? 0 : 6} 
            className={columnClassName}
            style={{ height: '80vh' }}
            >
            {documents.find(doc => doc.document_id === selectedDocumentId)?.document_file_url_signed ? (
            <div>

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                <div style={{ 
                  // border: '1px solid rgba(0, 0, 0, 0.3)',
                  //height: '750px' 
                  height: '90vh'
                  }}>
                    <Viewer
                        // fileUrl={`${process.env.PUBLIC_URL}/2023-03-15 Lease-final-signed.pdf`}
                        fileUrl={documents.find(doc => doc.document_id === selectedDocumentId).document_file_url_signed}
                        // fileUrl={ff}
                        // fileUrl={`${process.env.PUBLIC_URL}/test.docx`}
                        plugins={[
                            defaultLayoutPluginInstance,
                            searchPluginInstance
                        ]}
                        initialPage={0}
                        onDocumentLoad={handleDocumentLoad}
                    />
                </div>
              </Worker>
            </div>
            ) : documents.find(doc => doc.document_id === selectedDocumentId)?.youtube_url ? (
              <div>
                <div style={{ position: 'relative', paddingBottom: '56.25%', width: '100%' }}>
                  <div style={{ position: 'absolute', top: 0, left: 15, width: '100%'}}>
                    {/* Render the video player */}
                    <YouTube
                      videoId={documents.find(doc => doc.document_id === selectedDocumentId).youtube_id}
                      opts={{
                        // height: '100%',
                        width: '100%',
                        playerVars: {
                          startSeconds: 0, // Set the start time to 0
                          origin: window.location.origin
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  {/* Render the transcript */}
                  {documents.find(doc => doc.document_id === selectedDocumentId)?.text_with_metadata && (
                    <div style={{ maxHeight: '35vh', overflowY: 'auto', paddingLeft: '15px', width: '100%'}}>
                      {documents.find(doc => doc.document_id === selectedDocumentId)?.text_with_metadata.map(entry => (
                        <p key={entry.start}><strong>{entry.start} sec</strong> : {entry.text}</p>
                      ))}
                    </div>
                    
                  )}
                </div>
              </div>
            ) : (
              null
            )}
            {/* <DocViewer documents={docs} /> */}
          </Col>
          )}
          <Col 
            xs={12} 
            md={showLeftColumn ? 9 : 6} 
            className={columnClassName}
            style={{ height: '85vh' }}
            >
            {/* <h2>Chat with: { selectedDocumentName } </h2> */}
            
            <div className="column-style">
              {/* {showLeftColumn? (
              <ChatHeader 
                selectedDocumentName={ selectedDocumentName }
                document={ documents.find(doc => doc.document_id === selectedDocumentId) }
                setQuestions={setQuestions}
              />
              ) : (
                null
              )} */}
              <div className="d-flex justify-content-between">
                <Form onSubmit={handleSubmitStreaming} className="flex-grow-1 mr-2">
                  <FormGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter a new question"
                      value={newQuestion}
                      onChange={e => setNewQuestion(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSubmitStreaming(e);
                        }
                      }}
                    />
                  </FormGroup>
                  {/* Radio buttons for Language Model Selector: */}
                  {/* <FormGroup>
                    <div className="d-flex align-items-center">
                      {languageModels.map(model => (
                        <div key={model.value} className="mr-4 mb-2">
                          <Form.Check
                            type="radio"
                            label={model.label}
                            value={model.value}
                            checked={selectedLanguageModel === model.value}
                            onChange={e => setSelectedLanguageModel(e.target.value)}
                          />
                        </div>
                      ))}
                      <FormLabel htmlFor="number-of-sources">Number of sources:</FormLabel>
                        <div className="d-flex">
                          <FormControl
                            style={{
                              width: '120%' }}
                            type="number"
                            min="1"
                            max="10"
                            defaultValue="5"
                            className="form-control-sm"
                            placeholder="Enter a number"
                            onChange={e => setNumSources(parseInt(e.target.value))}
                          />
                      </div>
                    </div>
                  </FormGroup> */}
                </Form>
                <div className='align-items-end'>
                  <Button 
                    ref={submitQuestionButtonRef}
                    type="submit" 
                    className="ml-2"
                    // onClick={handleSubmit}
                    onClick={handleSubmitStreaming}
                    style={{ marginRight: '15px' }}
                    >
                    {isSubmitting ? (
                      <>
                        <Spinner animation="border" size="sm" /> Submitting...
                      </>
                    ) : (
                      <>Submit</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
            {/* <br></br> */}
            {/* <button   
              className="btn btn-primary btn-lg d-flex align-items-center justify-content-center rounded-circle"
              style={{position: 'absolute', top: '50%', transform: 'translateX(-80%)', zIndex: 999}}
              onClick={toggleLeftColumn}
            >
              {showLeftColumn ? <FaChevronRight /> : <FaChevronLeft /> }
            </button> */}
            {isAuthenticated() ? (
              <div className="list-container-questions column-style" 
                style={{ height: "100%", overflowY: "auto"}}>
                <div style={{ overflowY: "scroll" }}>
                  
                  {/* Streaming question */}
                  {isSubmitting? (
                  <ListGroup.Item 
                    className="text-left">
                      <h5 
                        className="text-left">
                          {newQuestion}
                          <CopyButton text={newQuestion} />
                      </h5>
                      <div>
                        {newQuestionSpinner ? (
                          // <Spinner animation="border" size="sm" />
                          <PulseLoader 
                            speedMultiplier={0.5}
                          />
                        ) : (
                          null
                        )}


                          <div className="bullet-list">
                            {/* <strong>Answer:</strong> */}
                            {/* {" "} */}
                            <ReactMarkdown>{streamingText}</ReactMarkdown>
                          </div>
        
                          {/* <VidyardPlayer uuid="avEY55MGNjggmHLcTcRWoP" /> */}
                          
                      </div>

                  </ListGroup.Item>
                  ) : (
                    null
                  )}

                  {/* Previous Questions */}

                  {filteredQuestions.map(question => (
                    <QuestionItem
                      question={question}
                      setQuestions={setQuestions}
                      questions={questions}
                      setSearchKeyword={setSearchKeyword}
                      showLeftColumn={showLeftColumn}
                      setShowLeftColumn={setShowLeftColumn}
                      extractionMethod={documents.find(doc => doc.document_id === selectedDocumentId).extraction_method}
                    />
                  ))}

                  {/* SUMMARY, Questions you could ask, and Key Terms */}

                  {selectedDocumentId ? (
                  <ListGroup.Item 
                    key={documents.find(doc => doc.document_id === selectedDocumentId).document_id}
                    className="bg-light"
                  >
                    <h4 className="text-left">
                      Summary:
                      <CopyButton text={`Summary: ${documents.find(doc => doc.document_id === selectedDocumentId)?.summary}` } />
                    </h4>
                    {documents.find(doc => doc.document_id === selectedDocumentId).summary ? (
                      <div className="bullet-list">
                        <ReactMarkdown>{documents.find(doc => doc.document_id === selectedDocumentId).summary}</ReactMarkdown>
                      </div>
                    ) : (
                      <div>
                        <PulseLoader 
                          speedMultiplier={0.5}
                        />
                        <p style={{ textAlign: 'center' }}>
                          Usually takes {`<`} 60 seconds ...
                        </p>
                      </div>

                      // <Spinner animation="border" role="status">
                      //   <span className="sr-only">Loading...</span>
                      // </Spinner>
                    )}

                    {/* Financial: Valuation */}
                    
                    {documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_valuation ? (
                      // <p><strong>Valuation:</strong>{documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_valuation}</p>
                      <div>
                        <h5 className="text-left">
                          Valuation:
                          {/* <CopyButton text={`Valuation:\n${documents.find(doc => doc.document_id === selectedDocumentId)?.summary_financial_valuation}` } /> */}
                        </h5>
                        <div className="bullet-list">
                          
                          <ReactMarkdown>{documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_valuation}</ReactMarkdown>
                        </div> 
                      </div>  
                    ) : (
                      null
                    )}

                    <p style={{textAlign: 'center'}}>
                      {documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_valuation && documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_valuation !== "" && (
                        <DisplaySources 
                          sources={documents.find(doc => doc.document_id === selectedDocumentId).sources_financial_valuation} 
                          setSearchKeyword={setSearchKeyword} 
                          showLeftColumn={showLeftColumn}
                          setShowLeftColumn={setShowLeftColumn}
                          extractionMethod={""}
                          />
                      )}
                    </p>

                    {/* Financial: Timeline */}

                    {documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_timeline ? (
                      <div>
                        <h5 className="text-left">
                          Timeline:
                          {/* <CopyButton text={`Valuation:\n${documents.find(doc => doc.document_id === selectedDocumentId)?.summary_financial_valuation}` } /> */}
                        </h5>
                        
                        <div className="bullet-list">
                          <ReactMarkdown>{documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_timeline}</ReactMarkdown>
                        </div> 
                      </div>
                    ) : (
                      null
                    )}

                    <p style={{textAlign: 'center'}}>
                      {documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_timeline && documents.find(doc => doc.document_id === selectedDocumentId).summary_financial_timeline !== "" && (
                        <DisplaySources 
                          sources={documents.find(doc => doc.document_id === selectedDocumentId).sources_financial_timeline} 
                          setSearchKeyword={setSearchKeyword} 
                          showLeftColumn={showLeftColumn}
                          setShowLeftColumn={setShowLeftColumn}
                          extractionMethod={""}
                          />
                      )}
                    </p>

                    {/* <h4 className="text-left">Key Numbers:</h4>
                    {documents.find(doc => doc.document_id === selectedDocumentId).keynumbers ? (
                      <DocumentKeyNumbers document={documents.find(doc => doc.document_id === selectedDocumentId)} />
                      // <p>{documents.find(doc => doc.document_id === selectedDocumentId).keynumbers}</p>
                    ) : (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    )} */}
                    <h4 className='text-left'>Questions you could ask:</h4>
                    {documents.find(doc => doc.document_id === selectedDocumentId).faqs ? (
                      <DocumentFaq 
                        document={documents.find(doc => doc.document_id === selectedDocumentId)}
                        setNewQuestion={setNewQuestion}
                        submitQuestionButtonRef={submitQuestionButtonRef}
                      />
                    ) : (
                      documents.find(doc => doc.document_id === selectedDocumentId).status !== 'complete' ? (
                      // <Spinner animation="border" role="status">
                      //   <span className="sr-only">Loading...</span>
                      // </Spinner>
                      <PulseLoader 
                        speedMultiplier={0.5}
                      />
                      ) : (
                        <p>No questions found.</p>
                      )
                    )}
                    {/* <h4 className="text-left">Key Terms:</h4>
                    {documents.find(doc => doc.document_id === selectedDocumentId).keyterms ? (
                      <DocumentKeyterms 
                        document={documents.find(doc => doc.document_id === selectedDocumentId)}
                        setNewQuestion={setNewQuestion}
                        submitQuestionButtonRef={submitQuestionButtonRef}
                      />
                    ) : (
                      documents.find(doc => doc.document_id === selectedDocumentId).status !== 'complete' ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      ) : (
                        <p>No key terms found.</p>
                      )
                    )} */}

                  </ListGroup.Item>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Col>

        </Row>
      </Container>
      ) : (
        <div>
          {/* <h2>Login</h2> */}
          <div className="text-gray-600 text-center mt-6">
                  {"<"} Return to <Link to="/" className="text-blue-600 hover:underline transition duration-150 ease-in-out">Home</Link>
          </div>
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-2">
            <h1 style={{ fontSize: '3.25rem', fontWeight: '800', fontFamily: 'ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"' }} className="h1">Sign in</h1>
          </div>
              
              
              
          <LoginForm onLogin={handleLogin} />
          <div className="text-gray-600 text-center mt-6">
                  Don’t you have an account? <Link to="/signup" className="text-blue-600 hover:underline transition duration-150 ease-in-out">Sign up</Link>
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </div>
  );
}

export { URL };
export default App;